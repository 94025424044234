/*------------------------------------------------------------
  5.1.2 footer
-----------------------------------------------------------*/
// Footer one style
.iron-footer-v1 {
    .iron-footer-top {
        background-color: #0066CC !important;
        padding: 1.75rem;
    }

    .footer-hr {
        margin: 0;
        border-bottom: 1px solid rgba($base, 0.2);
    }

    * {
        color: $base;
    }

    .footer-widget-title {
        h6 {
            font-weight: $font-weight-base;
            text-transform: uppercase;
        }
    }

    .iron-footer-menu-list {
        padding: 0;

        .list-item {
            padding: 0 0 0.3125rem 0;
            margin-bottom: 0.625rem;

            span {
                color: $base;
                text-transform: capitalize;
                font-size: 0.875rem;
            }
        }
    }

    .iron-footer-bottom {
        padding: 2.1875rem 0;
        background-color: #404d5f;
    }
}